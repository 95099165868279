<template>
    <div id="sidebar" class="d-flex flex-column justify-content-between py-2 px-4 me-4">
        <div id="sidebar-top">
            <a href="/" class="logo h3 mt-2">ScienceBase</a>
            <hr/>
            <div class="mt-4 form-group">
                <select class="form-control" v-model="active_dataset">
                    <option value="priorart_cordis">Cordis</option>
                    <option value="priorart_nwopen">NWOpen</option>
                </select>
            </div>
            <MenuItem 
                v-if="$store.getters.getActiveDataset['available_insights'].includes('leaderboard')" 
                icon='table' 
                title='Table'
                @click="set_graph('leaderboard')"
            />
            <MenuItem 
                v-if="$store.getters.getActiveDataset['available_insights'].includes('org_benchmark')" 
                icon='bar-chart fa-fix-bar' 
                title='Benchmark' 
                @click="set_graph('org_benchmark')"/>
            <MenuItem 
                v-if="$store.getters.getActiveDataset['available_insights'].includes('sankey')" 
                icon='code-branch fa-rotate-90' 
                title='Flow' 
                @click="set_graph('sankey')
            "/>
            <MenuItem 
                v-if="$store.getters.getActiveDataset['available_insights'].includes('network_graph')" 
                icon='diagram-project' 
                title='Network' 
                @click="set_graph('network_graph')
            "/>
            <MenuItem 
                icon='filter' 
                title='Filter' 
                @click="show.filter=1-show.filter"
            />
            <Filter v-show="show.filter"/>
        </div>
        <div id="sidebar-bottom">
            <p class="text-white">Powered by</p>
            <a href="https://armanl.eu" target="_blank"><img class="arma-logo" src="../assets/img/arma_logo.png"/></a>
            <a href="/logout/" class="text-decoration-none"><MenuItem icon='sign-out' title='Logout' /></a>
        </div>
    </div>
</template>

<script>
import MenuItem from './sidebar/MenuItem.vue'
import Filter from '@/components/Filter.vue'

export default {
    name: 'Sidebar',
    components: {MenuItem, Filter},
    computed: {
        active_dataset: {
            get() {
                return this.$store.state.data_filter.dataset;
            },
            set(value) {
                this.$store.commit('SET_FILTER', {field: 'dataset', value: value})
                this.$store.dispatch('get_leaderboard')
                this.$store.dispatch('update_all_data')
            }
        },
    },
    data: function() {return ({
        show: {
            graphs:1,
            filter:1,
        }
    })},
    methods: {
        set_graph(graph_title) {
            this.$store.commit('SET_DISPLAYED_GRAPH', graph_title)
        }
    }
}
</script>

<style>
#sidebar {
    background-color:#1E304F;
    width:300px;
}
#sidebar h3, #sidebar h5, #sidebar h6, .sub-menu {
    color:#ffe9bc;
}
.primary-color {
    color: #DAA82E;
}
.sub-menu {
    margin-left:2rem;
}
.sub-menu:hover {
    cursor:pointer;
    text-decoration:underline;
}
.arma-logo {
    max-width:80%;
}
.logo {
    text-decoration:none;
    color:#ffe9bc!important;
}
.fa-fix-bar {
    transform: scale(-1, 1) rotate(270deg);
}
</style>