<template>
    <h5 role="button" class="mt-4"><i :class="'fa-'+icon" class="fa-solid me-2"></i>{{title}}</h5>
</template>

<script>
export default {
    name:"SidebarMenuItem",
    props:['icon', 'title']
}
</script>

<style>

</style>