import { createStore } from 'vuex';
import $ from 'jquery';
import _ from 'lodash';


export default createStore({
    state: {
        datasets:{
            "priorart_nwopen": {
                name: "NWOpen",
                geography: "NL",
                available_filters: [
                    "organisation_ids",
                ],
                available_insights: [
                    'leaderboard',
                    'org_benchmark',
                    'network_graph',
                ]
            },
            "priorart_cordis": {
                name: "CORDIS",
                geography: "EU",
                available_filters: [
                    "organisation_ids",
                    "programme",
                    "country"
                ],
                available_insights: [
                    'leaderboard',
                    'org_benchmark',
                    'sankey',
                    'network_graph'
                ]
            }
        },
        data_filter: {
            country: [],
            dataset: 'priorart_cordis',
            organisation_ids: [],
            programme: ["10000010","31045244","31001283","31048036","43108390"],
            flattened_programme: [],
        },
        display: {
            active: 'leaderboard',
            sort_field: 'partner_subsidy',
            graphs: {
                leaderboard: {},
                projects_per_program: {
                    visualisation: 'sankey'
                },
                org_benchmark: {
                    xAxisBy: ["organisation_id", "year"], //year
                    stackBy: "rel_type", //coop_type
                    yAxisBy: "project_count" //partner_subsidy
                },
            },
        },
        graph_data: {
            projects_per_program: {
                nodes: [],
                links: []
            },
            org_benchmark: {
                xAxis: [],
                yAxis: [],
                grid: [],
                series: []
            },            
            leaderboard:[],
            treemap: {},
        },
        is_loading: 0,
        series: [],
        maxY:0,
        network_graph: {
            nodes: [
                {
                    id: "node1",
                    label: "Circle1",
                    x: 150,
                    y: 150
                },
                {
                    id: "node2",
                    label: "Circle2",
                    x: 400,
                    y: 150
                }
            ],
            edges: [
                {
                source: "node1",
                target: "node2"
                }
            ],
            categories: [],
        },
        organisation_id_map: {},
        organisation_options: [],
        topic_tree: [{
                id: "a",
                label: "Loading tree",
                children: [
                {
                    id: "b",
                    label: "Child placeholder",
                },
                ],
            },
            {
                id: "c",
                label: "sibling placeholder",
            },
        ],
        topic_tree_label_map: {},
        url_params: {
            'dataset': {type: 'string'},
            'organisation_ids': {type: 'list'},
            'programme': {type: 'list'},
            'country': {type: 'list'},
        }
    },
    actions: {
        update_all_data: function() {
            this.dispatch('get_projects_per_program');
            this.dispatch('get_org_benchmark');
            this.dispatch('get_collaborations');
        },
        get_leaderboard: function({commit, dispatch, getters}) {
            let _this = this
            console.log('getting leaderboard')
            this.sb_api.post(`/api/org_comparison/`, getters.getFilters)
                .then(function(response) {
                    commit('MERGE_GRAPH_DATA', {graph_title: 'leaderboard', graph_data: response.data.datatable})
                    commit('ADD_ORGIDS', response.data.datatable)
                    commit('ADD_ORG_OPTIONS', _.keyBy(response.data.datatable, 'organisation_id'))
                });
          },
          get_collaborations: function({commit, dispatch, getters}) {
            let _this = this
            this.sb_api.post(`/api/find_collaborations/`, getters.getFilters).then(function(response) {
                _this.state.network_graph = response.data
                _this.state.network_graph.nodes.forEach(node => {
                    // count the number of connections to each node
                    let edges = _this.state.network_graph.edges.filter(edge => {
                        return edge.target == node.id
                    })
                    node.degrees = edges.length
                    // set the node name
                    if(!_this.state.organisation_id_map[node.id]) {
                        _this.state.organisation_id_map[node.id] = node.name
                    }
                    // If the node is an organisation node from the organisation_id filter
                    // (and not a partner), fix the initial position on a circle.
                    if (node.type == 'organisation') {
                        let angle = 2 * Math.PI * (node.category + 1) / response.data.categories.length
                        node.x = Math.cos(angle) * 600 + 800
                        node.y = Math.sin(angle) * 500 + 700
                        node.fixed = true
                    }
                    return node
                })
                _this.state.network_graph.categories.forEach(category => {
                    category.name = _this.state.organisation_id_map[category.id]
                    return category
                })
            })
          },
          get_projects_per_program: function({commit, dispatch, getters}) {
            // Sometimes, the flattened programs are not properly reactive, so before sending this, we update them.
            commit("SET_FILTER", { field: "flattened_programme", value: getters.getDescendants });
            this.sb_api.post(`/api/program_funding_flow/`, getters.getFilters).then(function(response) {
                let filtered_nodes = _.filter(response.data.nodes, function(item) {return item['depth']<3})
                let refactored_nodes = _.map(filtered_nodes, (node) => {node.name = node.corda_id; return node})
                let filtered_links = _.filter(response.data.edges, function(item) {return item['depth']<3 && item['depth'] > 0})
                let refactored_links = _.map(filtered_links, function(item) {
                    // the sankey chart requires a 'source' and 'target' attribute, that corresponds with a 'name' attribute
                    item['source'] = item['parent_corda_id']
                    item['target'] = item['child_corda_id']
                    return item
                }) 
                commit('SET_GRAPH_DATA', {graph_title: 'projects_per_program', graph_data: {
                    nodes: refactored_nodes,
                    links: refactored_links
                }})
                commit('SET_GRAPH_DATA', {graph_title: 'treemap', graph_data: Object.values(helperMethods.parse_linked_tree_to_nested(response.data.edges, 1, 4)['tree_obj'])})
                });
            },
            get_org_benchmark: function({commit, getters}) {
                let _this = this;
                this.is_loading = 1;
                // if there is no org selection, we should not retrieve data
                if (!this.state.data_filter.organisation_ids.length) return false;
                this.sb_api.post(`/api/org_funding_timeline/`,getters.getFilters).then(function(response) {
                    commit('SET_GRAPH_DATA', {graph_title: 'org_benchmark', graph_data: {raw: response.data}})
                    _this.dispatch('make_timeline_chart');
                    _this.is_loading = 0
                });
            },
            make_timeline_chart:function({commit, getters}) {
                this.state.display.graphs.org_benchmark.show = 1;
                var raw_graph_data = this.state.graph_data['org_benchmark']['raw']
                let yAxisBy = this.state.display.graphs.org_benchmark.yAxisBy
                let stackBy = this.state.display.graphs.org_benchmark.stackBy
                let xAxisBy = this.state.display.graphs.org_benchmark.xAxisBy
                this.state.maxY = 0
                var xAxisData = {
                    'year': raw_graph_data['years'],
                    'organisation_id': getters.getFilters['organisation_ids']
                }
                //make series: if there is only one xAxisBy, we only need a flat barchart
                if (xAxisBy.length == 1) {
                    xAxisBy = xAxisBy[0]
                    this.dispatch('make_flat_barchart', {xAxisBy, stackBy, xAxisData, yAxisBy, raw_graph_data})
                }
                //make series: If there is a nested X-Axis, the make series function needs to be called N times
                if (xAxisBy.length == 2) {
                    console.log('making a nested barchart!')
                    var xAxisList = xAxisBy
                    this.dispatch('make_nested_barcharts', {xAxisList, stackBy, xAxisData, yAxisBy, raw_graph_data})
                }
            },
            make_flat_barchart({commit, getters}, {xAxisBy, stackBy, xAxisData, yAxisBy, raw_graph_data}) {
                let yAxis = [{type:'value'}]
                let xAxis = {         
                    type: 'category',
                    data: xAxisData[xAxisBy],
                    axisLabel: {
                        overflow: 'break',
                        interval: 0,
                        width: 350,
                        formatter: function(value) {
                            if (xAxisBy == 'organisation_id') {
                                return getters.getNameForOrgid[value]
                            }
                            return value
                        }
                    }
                }
                let graph_data = raw_graph_data
                let index = 0
                commit('MAKE_SERIES', {graph_data, stackBy, xAxisBy, xAxisData, yAxisBy, index})
                console.log('made series')
                commit('SET_GRAPH_DATA', {graph_title: 'org_benchmark', graph_data: {raw: raw_graph_data, yAxis:yAxis, xAxis:xAxis, series:this.state.series}})
            },
            make_nested_barcharts({commit, getters}, {xAxisList, stackBy, xAxisData, yAxisBy, raw_graph_data}) {
                // xAxisList[0] represents the dimension for which we want mulitple charts:
                // xAxisList[1] represents the dimension that should be in every chart
                var splitSeries = xAxisData[xAxisList[0]]
                var xAxisBy = xAxisList[1]
                var filtered_graph_data = {}
                _.forEach(splitSeries, function(item, index) {
                    filtered_graph_data[stackBy] = raw_graph_data[stackBy].filter((datapoint) => {
                        return datapoint[xAxisList[0]] == item
                    });
                    let graph_data = filtered_graph_data
                    console.log(graph_data)
                    commit('MAKE_SERIES', {graph_data, stackBy, xAxisBy, xAxisData, yAxisBy, index})
                })
                // setup Y and X objects and grid:
                var xAxisObjects = []
                var yAxisObjects = []
                var grid = []
                let gridIndex=0
                let gridMembers = xAxisData[xAxisList[0]].length
                let gridSize = 100 / gridMembers
                _.forEach(xAxisData[xAxisList[0]], function(xAxisDatapoint, name) {
                    xAxisObjects.push({
                        type: 'category',
                        axisLabel: name,
                        gridIndex: gridIndex,
                        data: xAxisData[xAxisList[1]],
                        axisLabel: {
                            // rotate: xAxisList[1] == 'year' ? 0:25,
                            overflow: 'truncate',
                            width: 350,
                            formatter: function(value) {
                                if (xAxisBy == 'organisation_id') {
                                    return getters.getNameForOrgid[value]
                                }
                                return value
                            }
                        }
                    })
                    yAxisObjects.push({
                        name: getters.getNameForOrgid[xAxisDatapoint],
                        nameLocation:'end',
                        nameGap: 10,
                        nameTextStyle: {
                            align:'left',
                            fontWeight:'800',
                        },
                        min:0,
                        type:'value',
                        gridIndex: gridIndex,
                    })
                    grid.push({
                        containsLabel: true,
                        top: gridIndex * gridSize + 5 + '%',
                        bottom: 100 - (gridIndex + 1) * gridSize + 5 + '%'
                    })
                    gridIndex++;
                })

                commit('SET_GRAPH_DATA', {graph_title: 'org_benchmark', graph_data: {raw: raw_graph_data, yAxis:yAxisObjects, xAxis:xAxisObjects, series: getters.getSeries, grid:grid}})
        },
        get_topic_tree: function({commit, dispatch, getters}) {
            let _this = this;
            console.log('getting topic tree...')
            this.sb_api.get('api/get_topic_tree/').then(response => {
                let parsed_tree = helperMethods.parse_linked_tree_to_nested(response.data['tree'], 1)
                // add all the corda_id labels to a tree:
                _.map(response.data.tree, function(topic) {
                    _this.state.topic_tree_label_map[topic.child_corda_id] = topic.child_title
                    _this.state.topic_tree_label_map[topic.parent_corda_id] = topic.parent_title
                })
                var flattened_tree_all_children = _.uniq(_.map(response.data['tree'], 'child_corda_id'))
                commit('SET_TOPIC_TREE', Object.values(parsed_tree['tree_obj']))
                commit("SET_FILTER", { field: "flattened_programme", value: flattened_tree_all_children});
                // if (_this.state.data_filter.organisation_ids.length) {
                //     _this.dispatch('parse_url_organisation_ids', _this.state.data_filter.organisation_ids.join(','))
                // }
            })
        },
        parse_url_organisation_ids: function({commit, getters}, organisation_ids_string) {
            let _this = this;
            this.sb_api.get(`/api/find_organisation?organisation_ids=${organisation_ids_string}`,).then(response => {
                console.log('url orgids response')
                console.log(response)
                commit('ADD_ORGIDS', response.data)
                commit('ADD_ORG_OPTIONS', _.keyBy(response.data, 'organisation_id'))
                let sorted_by_organisation_id = response.data.sort(function (a, b) {
                    return _this.state.data_filter.organisation_ids.indexOf(parseInt(a.organisation_id)) - _this.state.data_filter.organisation_ids.indexOf(parseInt(b.organisation_id));
                });
                commit('SET_ORG_SELECTION', sorted_by_organisation_id)
                commit('UPDATE_LIST_FILTER', {field: 'organisations', 'list':response.data})
                this.dispatch('get_projects_per_program');
                this.dispatch('get_org_benchmark');
            })
        },
        update_org_selection: function({commit, getters}, value) {
            commit('SET_FILTER', {field: 'organisation_ids', value: value})
            this.dispatch('get_projects_per_program');
            this.dispatch('get_org_benchmark');
            this.dispatch('get_collaborations');
        }
    },
    getters: {
        getSeries: function(state) { return state.series },
        getMaxY: function(state) {
            return Math.round(1.05*state.maxY)
        },
        getFilters: function(state) {
            return state.data_filter
        },
        getActiveDataset: function(state) {
            return state.datasets[state.data_filter.dataset]
        },
        getDisplay: function(state) {
            return state.display;
        },
        getNameForOrgid: function(state) {
            return state.organisation_id_map
        },
        getProgrammeName: function(state) {
            return state.topic_tree_label_map
        },
        getSelectedOrganisations: function(state) {
            return _.at(state.organisation_options, state.data_filter.organisation_ids)
        },
        getDescendants: function(state) {
            let parent_descendant_list = [...state.data_filter.programme]
            state.topic_tree.forEach(function(parent) {
                parent_descendant_list.concat(add_children(parent, parent_descendant_list))
            })
            function add_children(parent, parent_descendant_list) {
                _.forEach(parent.children, function(child) {
                    if (parent_descendant_list.includes(parent.id)) {parent_descendant_list.push(child.id)}
                    parent_descendant_list.concat(add_children(child, parent_descendant_list))
                });
                return parent_descendant_list
            }
            return parent_descendant_list
        }
    },
    mutations: {
        ADD_ORGIDS: function(state, payload) {
            payload.forEach(function(item) {
                state.organisation_id_map[item.organisation_id] = item.name;
            });
        },
        ADD_ORG_OPTIONS: function(state, payload) {
            state.organisation_options = {...state.organisation_options, ..._.keyBy(payload, 'organisation_id')}
        },
        SET_GRAPH_DATA: function(state, payload) {
            state.graph_data[payload.graph_title] = payload.graph_data;
        },
        MERGE_GRAPH_DATA: function(state, payload) {
            let old_data = state.graph_data[payload.graph_title]
            let data_fields = ['partner_subsidy', 'project_count']
            if (old_data.length < 2) {
                state.graph_data[payload.graph_title] = payload.graph_data
            }
            else {
                let new_data = payload.graph_data
                let new_data_ids = _.map(new_data, 'organisation_id') 
                old_data.forEach(function(datapoint, key) {
                    // case if old_data point is not in new data
                    if (!new_data_ids.includes(datapoint.organisation_id)) {
                        delete old_data[key]
                    }
                    // if old data point is also in new data, update data fields
                    else {
                        var new_datapoint = _.filter(new_data, {'organisation_id': parseInt(datapoint.organisation_id)})[0]
                        data_fields.forEach(function(field) {
                            try {
                                old_data[key][field] = new_datapoint[field]
                            }
                            catch {
                                console.log('Could not update ' + field + ' for data with key ' + key)
                            }
                        });
                        new_data = _.reject(new_data, function(item) {return item.organisation_id == datapoint.organisation_id} )
                    }
                });
                let merged_data = [...old_data.filter(function() {return true;}), ...new_data]
                merged_data = _.orderBy(merged_data, state.display.sort_field, 'desc')
                state.graph_data[payload.graph_title] = merged_data.filter(function() {return true;})
            }
        },
        SET_DISPLAYED_GRAPH: function(state, payload) {
            state.display.active = payload;
        },
        TOGGLE_GRAPH_DISPLAY_SETTING: function(state, payload) {
            state.display.graphs[payload['graph_title']][payload['property']] = payload['value'];
        },
        UPDATE_LIST_FILTER: function(state, payload) {
            let field = payload['field']
            if(typeof(payload['list']) != 'undefined') {
                state.data_filter[field] = payload['list']
            }
            if(payload['item']) {
                if(state.data_filter[field].includes(payload['item'])) {
                    var ToBeRemovedIndex = state.data_filter[field].indexOf(payload['item'])
                    state.data_filter[field] = state.data_filter[field].splice(ToBeRemovedIndex, 1)
                }
                else state.data_filter[field].push(payload['item'])
            }
        },
        SET_ORG_SELECTION: function(state, payload) {
            state.data_filter['organisation_ids'] = _.map(payload, 'organisation_id')
        },
        SET_FILTER: function(state, payload) {
            state.data_filter[payload['field']] = payload['value']
            if(Object.keys(state.url_params).includes(payload['field'])) {
                let old_query = this.$router.currentRoute._value.query
                let new_query = {}
                if (state.url_params[payload['field']].type == 'list') {
                    new_query[payload['field']] = payload['value'].join(',')
                }
                else if (state.url_params[payload['field']].type == 'string') {
                    new_query[payload['field']] = payload['value']
                }
                this.$router.replace({'query': {...old_query, ...new_query}})
            }
        },
        SET_TOPIC_TREE: function(state, payload) {
            state.topic_tree = payload
        },
        MAKE_SERIES: function(state, {graph_data, stackBy, xAxisBy, xAxisData, yAxisBy, index}) {
            let series = []
            // stackBy.forEach(function(stack) {
            let stack = stackBy
            var group_per_stack = _.groupBy(graph_data[stack], stack)
            _.map(group_per_stack, function(grouped_projects, stack_label) {
                var values_per_x = []
                let group_stack_per_x_category = _.groupBy(grouped_projects, xAxisBy)
                _.forEach(xAxisData[xAxisBy], (x_datapoint) => {
                    values_per_x.push(_.sumBy(group_stack_per_x_category[x_datapoint], yAxisBy))  
                });
                series.push({
                    name: stack_label,
                    type: 'bar',
                    xAxisIndex: index,
                    yAxisIndex: index,
                    stack: stack+index,
                    label: {
                        normal: {
                            show: true, 
                            position: 'inside',
                            formatter: (params) => {
                                if (!params.data) return '';
                                let per_stack_values = _.groupBy(state.graph_data['org_benchmark']['series'], 'stack')
                                let sum = _.sumBy(per_stack_values[stack+index], function(datapoint) {return datapoint['data'][params.dataIndex]})
                                let relative = Math.round(100 * params.data / sum ) + '%';
                                state.maxY = Math.max(sum, state.maxY)
                                return relative;
                            }
                        }
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: values_per_x
                })
            });
            // })
            if (index) {
                state.series = [...state.series, ...series]
            }
            else state.series = series
        }

    }
})

const helperMethods = {
    parse_linked_tree_to_nested: function(linked_tree, minDepth=null, maxDepth=null) {
        var tree_obj = {}
        console.log(linked_tree)
        if (maxDepth === null) {var maxDepth = _.maxBy(linked_tree, 'depth')['depth'];}
        if (minDepth === null) {var minDepth = _.minBy(linked_tree, 'depth')['depth'];}
        // start at maximum depth
        for (let i = maxDepth; i >= minDepth; i--) {
          var items_at_i = _.filter(linked_tree, {'depth': i});
          _.each(items_at_i, function(item_at_i) {
            // check if the parent already exists, then append child to parent node
              if (!(item_at_i['parent_corda_id'] in tree_obj)) {
                  tree_obj[item_at_i['parent_corda_id']] = {
                  'id': item_at_i['parent_corda_id'],
                  'label': item_at_i['parent_title'],
                  'children': [],
                }
              }
              if (item_at_i['child_corda_id'] in tree_obj) {
                  tree_obj[item_at_i['parent_corda_id']]['children'].push(tree_obj[item_at_i['child_corda_id']])
                  // now delete the item as it is attached in hierarchy
                  delete tree_obj[item_at_i['child_corda_id']]
                }
                else { // create leaf node
                    // attaching the child element to it's parent
                    let child_elem = {
                        'id':item_at_i['child_corda_id'],
                        'label': item_at_i['child_title'],
                        'value': item_at_i['value']?? 0
                    }
                    // in a new list if neccesary
                    if (!('children' in tree_obj[item_at_i['parent_corda_id']])) {
                        tree_obj[item_at_i['parent_corda_id']]['children'] = [child_elem]
                    }
                    // or pushing if already present:
                    else {
                        tree_obj[item_at_i['parent_corda_id']]['children'].push(child_elem)
                    }
                }
            });
          }
        return {'tree_obj': tree_obj};
    }
}
