<template>
  <div class=" py-4 w-100 h-100">
    <div v-show="!$store.state.data_filter.organisation_ids?.length">
      <i class="fa-solid fa-warning"></i> Please select an organisation to use this display, either through the Table or by finding them in the filter on the left.
    </div>
    <div v-if="$store.state.data_filter.organisation_ids?.length && !$store.state.is_loading">
      <GraphOptions v-show="show.options" @close_options="show.options=0" />
      <v-chart class="chart" :auto-resize="true" :option="activeOptions"/>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart, SankeyChart, TreemapChart, SunburstChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
  GraphicComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import GraphOptions from './GraphOptions.vue'

use([
  BarChart,
  LineChart,
  CanvasRenderer,
  SankeyChart,
  TreemapChart,
  SunburstChart,
  GraphicComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent
]);

export default {
  name: 'Chart Container',
  components: {VChart, GraphOptions},
  computed: {
    colors: function() {return ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#5470c6']},
    activeOptions: function() {
      return {...this.shared, ...this.options[this.$store.getters.getDisplay.active]}
    },
    shared: function() {
      var _this = this;
      let shared_options_object = {
        graphic: [],
      }
      if (this.$store.getters.getActiveDataset.available_filters.includes('programme')) {
        shared_options_object.graphic.push({
            type:'text',
            z: 100,
            left: 'center',
            top: 'bottom',
            draggable: true,
            style: {
              fill: '#333',
              width: '800px',
              overflow: 'break',
              text: 'Selected programmes:' + _.map(this.$store.getters.getFilters['programme'], function(id) {
                return ` ${_this.$store.state.topic_tree_label_map[id]}`
              }),
              font: '8px'
            }
        })
      }
      shared_options_object['legend'] = {
          orient: 'vertical',
          align: 'right',
          right: '0',
          top:'50%',
      }
      shared_options_object['toolbox'] = {
          feature: {
            myTool1: {
                show: true,
                title: 'Settings',
                iconStyle: 'fa-solid',
                icon: "path://m487.4 315.7-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z",
                onclick: function (){
                  _this.show.options = 1 - _this.show.options;
                }
            },
            saveAsImage: {},
            dataView: {},
          }
        }
      return shared_options_object
    },
    options: function() {
      var _this = this;
      return { 
        org_benchmark: {
          title: {
            text: 'Organisation Benchmark',
            left: '50%',
            textAlign: 'center'
          },
          tooltip: {
              formatter: function(hovered_stack) {
                let yAxisBy = _this.$store.getters.getDisplay['graphs']['org_benchmark']['yAxisBy']
                let xAxisBy = _this.$store.getters.getDisplay['graphs']['org_benchmark']['xAxisBy']
                if (xAxisBy == 'organisation_id') {
                  var label_text = _this.$store.getters.getNameForOrgid[hovered_stack[0]['axisValue']]
                }
                else { var label_text = hovered_stack[0]['name']}
                hovered_stack.forEach(function(row) {
                  if (yAxisBy == 'partner_subsidy') {
                    if(!(row.value)) {return true}
                    var print_value = row.value.toLocaleString(navigator.language, {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'})
                  }
                  else if (yAxisBy == 'project_count') { var print_value = `${row.value} projects`}
                  else { var print_value = row.value }
                  label_text += '<div class="d-flex mb-0 justify-content-between">'
                  label_text += `<div>${row.marker}`
                  label_text += `${row.seriesName}</div>`
                  label_text += `<strong class="text-right ms-2">${print_value}</strong></div>`
                });
                return label_text
              },
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '5%',
              containLabel: true
          },
          series: this.$store.state.graph_data['org_benchmark']['series'],
          yAxis: _.map(this.$store.state.graph_data['org_benchmark']['yAxis'], function(axis) {
            axis.max = _this.$store.getters.getMaxY
            return axis
          }),
          xAxis: this.$store.state.graph_data['org_benchmark']['xAxis'],
          grid: this.$store.state.graph_data['org_benchmark']['grid'],
        },
        sankey: {
          title: {
            text: 'Funding Flow',
            left: '50%',
            textAlign: 'center'
            },
          layoutIterations: 0, // required to use our own sorting
          toolbox: {
            show: true,
            feature: {
                myTool1: {
                  show: true,
                  title: 'Settings',
                  iconStyle: 'fa-solid',
                  icon: "path://m487.4 315.7-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z",
                  onclick: function (){
                    _this.show.options = 1 - _this.show.options;
                  }
              },
              saveAsImage: {}
            },
          },
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            formatter: function (obj) {              
                return obj['name'] + 
                      "<br/>"+ obj.data['long_title'] + ' - ' +
                      obj['value'].toLocaleString(navigator.language, {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'});
            },
          },
          series: [
            {
              type: 'sankey',
              nodeAlign: "left",
              data: this.$store.state.graph_data['projects_per_program']['nodes'],
              links: this.$store.state.graph_data['projects_per_program']['links'],
              emphasis: {
                focus: 'adjacency'
              },
              label: {
              display:true,
              formatter: function(obj) {
                console.log(obj)
                return `${_this.$store.state.topic_tree_label_map[obj.data.corda_id]} (${obj.data.value.toLocaleString(navigator.language, {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'})})`
              }
            },
            lineStyle: {
              color: 'gradient',
              curveness: 0.5
            }
          }
          ]
        },
        sunburst: {
          title: {
              text: 'Sunburst Chart',
              left: '50%',
              textAlign: 'center'
            },
          series: [
          {
            type: 'sunburst',
            data: this.$store.state.graph_data['treemap'],
            universalTransition: true,
            radius: [0, '50%'],
            color: ['#c5c5c5', ...this.colors],
            valueFormatter: (value) => value.toLocaleString(navigator.language, {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'}),
            label: {
              overflow: 'truncate',
              minAngle: 15,
              width: '150',
              formatter: function(obj) {
                  if (typeof(obj['data']['id']) == 'undefined') return 'Go up'
                  return `${_this.$store.state.topic_tree_label_map[obj.data.id]}`
                }
            },
            levels: [
              {
                label: {
                  r0: '0%',
                  r: '0%',
                  rotate: 'tangential',
                  show: true,
                }
              },
              {
                r0: "10%",
                r: "25%",
                label: {
                    rotate: 'tangential',
                }
              },
              {
                r0: "25%",
                r: "45%",
                label: {
                    overflow: 'break',
                    rotate: 'tangential',
                },
              },
              {
                r0: "45%",
                r: "70%",
                label: {
                    overflow: 'break',
                    rotate: 'tangential',
                },
              },
              {
                r0: "70%",
                r: "80%",
                label:{
                    show:false,
                    formatter: '',
                    rotate:0,
                    position:'outside',
                  }
              },
              {
                r0: "80%",
                r: "90%",
                label:{
                    show:false,
                    rotate:0,
                    position:'outside',
                    formatter: '',
                }
              },
            ],
            tooltip: {
              show:true,
            },
            emphasis: {
                focus: 'ancestor',
                label: {
                  formatter: function (obj) {
                  if (typeof(obj['data']['id']) == 'undefined') return 'Go up'
                  return _this.$store.state.topic_tree_label_map[obj.data.id] + '\n' +
                        obj['value'].toLocaleString(navigator.language, {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'});
                  },
                }
            },
          }],
        },
        treemap: {
            legend: {show:true,},
            color: this.colors,
            tooltip: {
              show:true,
              formatter: function (obj) {              
                return `<strong>${_this.$store.state.topic_tree_label_map[obj['data']['id']]}</strong><br/>`+ 
                      obj['value'].toLocaleString(navigator.language, {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'});
              },
            },
            label: {
              overflow: 'truncate',
              width: '150',
              formatter: function(obj) {
                  if (typeof(obj['data']['id']) == 'undefined') return 'Go up'
                  return `${_this.$store.state.topic_tree_label_map[obj.data.id]}`
                }
            },
            series: [
              {
                type: 'treemap',
                data: this.$store.state.graph_data['treemap'],
              },
            ]
        }
      }
    }
  },
  data: function(){return ({
    show: {
      options:0, 
      }
  })},
  mounted: function() {
    this.$store.dispatch('get_projects_per_program');
    this.$store.dispatch('get_org_benchmark');
  },
}
</script>

<style scoped>
.chart-title {
  text-transform:capitalize;
}
.chart {
  height: calc(100vh - 2rem);
  width: calc(100vw - 350px);
}
</style>