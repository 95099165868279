<template>
  <div id="sidebar_filters">
    <div class="d-flex flex-column my-4 justify-content-around">
      <div class="form-group" v-if="$store.getters.getActiveDataset['available_filters'].includes('organisation_ids')">
        <div>
          <div> 
            <i class="fa-solid fa-building me-2 mb-2"></i>Organisation</div>
            <Multiselect placeholder="Type organisation name" 
              v-model="org_selection"
              v-if="Object.keys($store.state.graph_data['leaderboard']).length"
              mode="tags" 
              :delay="100" 
              :min-chars="3" 
              :filter-results="true"
              :close-on-select="false"
              :options="query_organisation"
              ref="org-multiselect" 
              label="name"
              valueProp="organisation_id"
              trackBy="name"
              :canClear="false"	 
              :searchable="true"
              id="org_selection">
              <template v-slot:option="{ option }">
                  <span class="fw-bold">{{ option.name }}</span>
                </template>
                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                      <div class="multiselect-tag is-user country" :class="{'is-disabled': disabled}" :title="option.name">
                          <div class='ellipsis-text'>{{ option.name }}</div>
                          <span v-if="!disabled"
                              class="multiselect-tag-remove"
                              @click="handleTagRemove(option, $event)">
                              <span class="multiselect-tag-remove-icon"></span>
                          </span>
                      </div>
                  </template>
            </Multiselect>
        </div>
      </div>
      <div class="mt-4 form-group" v-if="$store.getters.getActiveDataset['available_filters'].includes('programme')">
        <label class="me-2 mb-2" for="programme-select"><i class="fa-solid fa-project-diagram me-2"></i>Programme</label>
          <treeselect 
            id="programme-select" 
            v-model="programme"
            ref="treeselect"
            :multiple="true"
            :options="topic_tree"
            :disableFuzzyMatching="true"
            >
            <template #option-label="{node}"><div class="vue-treeselect__label" :title="node.name">{{node.label}}</div></template>
          </treeselect>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import Multiselect from '@vueform/multiselect'
import {mapState} from 'vuex'

export default {
  name: "Filter",
  components: { Treeselect, Multiselect },
  computed: {
    ...mapState(['organisation_options', 'topic_tree']),
    country: {
      get() {
        return this.$store.getters.getFilters["country"];
      },
      set(value) {
        this.$store.commit("SET_FILTER", { field: "country", value: [value] });
      },
    },
    org_selection: {
      get() {return this.$store.state.data_filter.organisation_ids},
      set(value) {
        this.$store.dispatch('update_org_selection', value)
      }
    },
    programme: {
      get() {
        return this.$store.getters.getFilters["programme"];
      },
      set(value) {
        console.log('setting programme filter to:')
        console.log(value)
        this.$store.commit("SET_FILTER", { field: "programme", value: value });
        this.$store.commit("SET_FILTER", { field: "flattened_programme", value: this.$store.getters.getDescendants });
        this.$store.dispatch('get_leaderboard')
        this.$store.dispatch('update_all_data')
      },
    },
  },
  data: function () {
    return {
    };
  },
  methods: {
    query_organisation: async function(query) {
      if (query !== null) {
        return this.sb_api.get("/api/find_organisation?q=" + encodeURIComponent(query)).then((response) => {
        this.$store.commit('ADD_ORG_OPTIONS', _.keyBy(response.data, 'organisation_id'))
        return Object.values(this.organisation_options)
        })
      }
      return Object.values(this.organisation_options)
    }
  },
  mounted: function() {
      if (this.$store.getters.getActiveDataset['available_filters'].includes('programme')) {
        this.$store.dispatch('get_topic_tree')
      }
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
#programme-select, .multiselect {
  color:black!important;
}
#sidebar_filters {
  color: white;
}
.selectize-input .item {
  max-width: 200px;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-height:1.4rem;
  overflow-y:hidden;
}
.selectize-control.plugin-remove_button .item {
    align-items: baseline!important;
}
.vue-treeselect__multi-value-label {
  max-width:200px;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow-y:hidden;
}
.vue-treeselect__menu {
  position:absolute;
  width:40vw;
}
#sidebar_filters .multiselect-placeholder {
    font-size:80%;
    padding-right:0!important;
}
#sidebar_filters .multiselect-wrapper {
    justify-content:flex-start;
}
#sidebar_filters .multiselect-tags {
    justify-content: flex-start;
    max-width:100%;
    overflow-x:hidden;
}
#sidebar_filters .multiselect-tag {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#sidebar_filters .multiselect-dropdown {
    overflow-x:visible;
    min-width:25vw;
}
.ellipsis-text {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
</style>
