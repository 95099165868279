<template>
  <div id="main" class="d-flex">
    <Sidebar />
    <DataTable v-show="$store.getters.getDisplay.active === 'leaderboard'"/>
    <ChartContainer v-if="['org_benchmark', 'sankey', 'sunburst', 'treemap'].includes($store.getters.getDisplay.active)"/>
    <NetworkGraph v-if="$store.getters.getDisplay.active == 'network_graph'"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import ChartContainer from '@/components/ChartContainer.vue'
import DataTable from '@/components/DataTable.vue'
import NetworkGraph from '@/components/NetworkGraph.vue'
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free/css/all.css'


export default {
  name: 'Home',
  components: {
    ChartContainer,
    DataTable,
    NetworkGraph,
    Sidebar,
  },
  data: function() {return({
    show: {
      options:1
    }
  })},
  created: function() {
    let _this = this;
    Object.entries(this.$route.query).forEach(function(value) {
      let field_name = value[0]
      let field_value = value[1]
      if (field_name == 'organisation_ids') {
        _this.$store.dispatch('update_org_selection', value[1].split(','))
      }
      else if (_this.$store.state.url_params[field_name] && _this.$store.state.url_params[field_name].type == 'list') {
        _this.$store.commit('SET_FILTER', {field: field_name, value:field_value.split(',')});
      }
      else if (_this.$store.state.url_params[field_name] && _this.$store.state.url_params[field_name].type == 'string') {
        _this.$store.commit('SET_FILTER', {field: field_name, value:field_value})
      }
    });
  },
  mounted: function() {
  },
}
</script>

<style>
#main {
  background-color:#f5f5f5!important;
  min-height:100vh;
  overflow-x:hidden;
}
.btn-opts {
  background-color: #1E304F!important;
  color: white!important;
}
</style>
