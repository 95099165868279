<template>
    <div>
        <div v-show="!$store.state.data_filter.organisation_ids?.length">
            <i class="fa-solid fa-warning"></i> Please select an organisation to use this display, either through the Table or by finding them in the filter on the left.
        </div>
        <div class="d-flex" v-if="$store.state.data_filter.organisation_ids?.length">
            <v-chart ref="network_graph" 
                @mouseover="hovering_over_chart($event)"
                @mouseleave="hovering_over_chart(false)"
                id="network_graph_canvas_container" 
                :auto-resize="true" 
                :option="activeOptions"
            />
            <div class="">
                <div class="me-5" id="network_graph_legend">
                    <div class="org-list">
                        <div class="d-flex justify-content-between legend-label"
                            :class="{'hovering': this.hover_node_names.includes(org_node.name)}"
                            :key="org_node.id" v-for="org_node in sorted_organisation_nodes"
                            @mouseenter="set_hover(org_node.name)"
                            @mouseleave="set_hover(false)"
                            @click="toggle_legend(org_node.category)"
                        >
                            <span :style="'color:'+get_echart_colors[org_node.category]">
                                <i class="fa-solid fa-square me-2"></i>
                                <span class="text-black">{{org_node.name}}</span>
                            </span>
                        </div>
                    </div>
                    <hr/>
                    <div class="partner-list">
                        <div class="d-flex justify-content-between legend-label" 
                            :class="{'hovering': this.hover_node_names.includes(node.name)}"
                            :key="'node'+node.id" 
                            @mouseleave="set_hover(false)"
                            @mouseenter="set_hover(node.name)" 
                            v-for="node in sorted_partner_nodes">
                            <span :style="'color:'+get_echart_colors[node.category]">
                                <i class="fa-solid fa-square me-2"></i>
                                <span class="text-black">{{node.name}}</span>
                            </span>
                            <span>{{node.degrees}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import VChart, { THEME_KEY } from "vue-echarts";
import {mapState} from 'vuex'
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { GraphChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TooltipComponent,
  LegendComponent,
  GraphChart,
  CanvasRenderer,
  LabelLayout
]);

export default {
    name: "NetworkGraph",
    components: {VChart},
    computed: {
        ...mapState(['network_graph', 'organisation_id_map']),
        sorted_organisation_nodes:function() {
            let org_nodes = _.filter(this.network_graph.nodes, (node) => {return node.type=='organisation'}) 
            return _.orderBy(org_nodes, 'category', 'asc')
        },
        sorted_partner_nodes: function() {
            let partner_nodes = _.filter(this.network_graph.nodes, (node) => {return node.type=='partner'}) 
            return _.orderBy(partner_nodes, 'degrees', 'desc')
        },
        get_echart_colors: function() {
            if(!this.$refs.network_graph) return ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
            else {
                return this.$refs.network_graph.getOption()['color']
            }
        },
        activeOptions: function() {
            let _this = this;
            return {
                title: {
                    text: 'Collaborative Network',
                    top: 'top',
                    left: 'left'
                },
                tooltip: {
                    formatter: function(params) {
                        if (params.dataType == 'node') {
                            let relationships = _.filter(_this.network_graph.edges, function(edge) {
                                return edge.target == params.data.id
                            })
                            let message = '<strong>'+ params.data.name + ':</strong>'
                            message += relationships.map(function(rel) {
                                return '<br/>' + rel.label +' projects with ' + _this.organisation_id_map[rel.source]
                            })
                            return message
                        }
                        if (params.dataType == 'edge') {
                            return params.data.label + ' projects between ' + _this.organisation_id_map[params.data.source] + ' and ' + _this.organisation_id_map[params.data.target]
                        }
                    }
                },
                emphasis: {
                    focus: 'adjacency',
                    label: {
                        show: true,
                    },
                    lineStyle: {
                        width: 3
                    }
                },
                // legend: [
                //     {
                //         // selectedMode: 'single',
                //         data: this.network_graph.categories.map(function (a) {
                //         return a.name;
                //         })
                //     }
                // ],
                series: [
                {
                    name: 'Connections',
                    type: 'graph',
                    layout: 'force',
                    data: this.network_graph.nodes,
                    links: this.network_graph.edges,
                    categories: this.network_graph.categories,
                    center: ["50%", "50%"],
                    roam: true,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: '{b}'
                    },
                    labelLayout: {
                        hideOverlap: true
                    },
                    force: {
                        repulsion: 80,
                        gravity: 0,
                    }
                }
                ]
            };
        }
    },
    data: function() { return({
        hover_node_names: [],
    })},
    mounted:function() {
        this.$store.dispatch('get_collaborations')
    },
    watch: {
    },
    methods: {
        hovering_over_chart: function(event) {
            if (event === false) {
                this.hover_node_names = false;
            }
            if (event.dataType == 'node') {
                this.hover_node_names = [event.data.name]
            }
            if (event.dataType == 'edge') {
                this.hover_node_names = [
                    this.organisation_id_map[event.data.source], 
                    this.organisation_id_map[event.data.target]
                ]
            }
        },
        set_hover: function(node_name) {
            if(node_name == false) {
                this.hover_node_names = []
                this.$refs.network_graph.dispatchAction({
                    type: 'showTip',
                    seriesIndex: 0,
                });
                this.$refs.network_graph.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                });
            }
            else {
                this.hover_node_names = [node_name]
                this.$refs.network_graph.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    name: node_name,
                });
                this.$refs.network_graph.dispatchAction({
                    type: 'showTip',
                    seriesIndex: 0,
                    name: node_name,
                });
            }
        },
    }
}
</script>

<style>
#network_graph_canvas_container {
    width:calc(100vw - 350px - 350px);
    height:100vh;
}
#network_graph_legend {
    width: 350px;
    height:80vh;
    top:10vh;
    position:absolute;
    background-color:white;
    border-radius:1em;
    display:flex;
    flex-direction:column;
}
.org-list {
    padding-left: 1rem;
    margin-top:1rem;
}
.partner-list {
    margin-bottom:1rem;
    padding: 0 1rem;
    overflow-y:scroll;
    position:relative;
    max-height:100%;
}
.legend-label {
    line-height:1.3rem;
    max-height:1.3rem;
    overflow-y: hidden;
    overflow-x: clip;
    text-overflow:ellipsis; 
    -o-text-overflow:ellipsis; 
    -ms-text-overflow:ellipsis; 
    cursor: pointer;
}
.legend-label.hovering {
    font-weight:700;
}
</style>