import {createApp} from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import router from '../../router'
import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';
import store from '../../store/store.js' // short for @/store/index
import selectize from '@selectize/selectize/dist/js/standalone/selectize.js'
import '@selectize/selectize/dist/css/selectize.css'
import axios from 'axios'


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const app = createApp(App)
              .use(router)


const sb_api = axios.create({
    headers: {
        "X-CSRFTOKEN": getCookie('csrftoken'),
        "withCredentials": true,
        "Content-Type": "application/json;charset=utf-8",

    }
});
const orcid_instance = axios.create({});
const openalex_instance = axios.create({headers: {
    'Content-Type': 'application/json;charset=UTF-8',
}});

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

app.config.globalProperties.sb_api = sb_api;
store.sb_api = sb_api;
app.config.globalProperties.$openalex = openalex_instance;
store.$openalex = openalex_instance;
store.$router = router
app.config.globalProperties.$orcid = orcid_instance;
app.use(store)
app.use($)
app.mount('#app')

