<template>
  <div class="graph_options position-absolute top-0 end-0 m-2 p-2 bg-white">
    <div role="button" @click="$emit('close_options')" class="position-absolute top-0 end-0 m-1">&times;</div>
    <div v-if="$store.getters.getDisplay.active=='org_benchmark'" class="d-flex flex-row align-items-top">
      <div class="d-flex flex-row mt-2 me-2">
        <div>
        <strong>X-Axis</strong>
            <div>
                <input type="checkbox" id="timeline" value="year" v-model="xAxisBy" />
                <label class="ms-1" for="timeline">Timeline</label>
            </div>
            <div>
                <input type="checkbox" id="organisation_id" value="organisation_id" v-model="xAxisBy" />
                <label class="ms-1" for="organisation_id">Organisation</label>
            </div>
        </div>
        <hr/>
        <div class="ms-4">
        <strong>Bar divider (stack)</strong>
            <div>
                <input type="radio" id="rel_type" value="rel_type" v-model="stackBy" />
                <label class="ms-1" for="rel_type">Participation type</label>
            </div>
            <div>
                <input type="radio" id="cooperation_type" value="cooperation_type" v-model="stackBy" />
                <label class="ms-1" for="cooperation_type">Cooperation type</label>
            </div>
        </div>
        <hr/>
        <div class="ms-4">
        <strong>Y-axis</strong>
            <div>
                <input type="radio" id="partner_subsidy" value="partner_subsidy" v-model="yAxisBy" />
                <label class="ms-1" for="partner_subsidy">Sum of EU subsidy (€)</label>
            </div>
            <div>
                <input type="radio" id="project_count" value="project_count" v-model="yAxisBy"/>
                <label class="ms-1" for="project_count">Project Count</label>
            </div>
        </div>
      </div>
    </div>
    <div v-if="['sankey', 'sunburst', 'treemap'].includes($store.getters.getDisplay.active)">
      Visualisation:
      <div>
        <input type="radio" id="sankey" value="sankey" v-model="visualisation" />
        <label class="ms-1" for="sankey">Sankey/Fluvial</label>
      </div>
      <div>
          <input type="radio" id="sunburst" value="sunburst" v-model="visualisation" />
          <label class="ms-1" for="sunburst">Sunburst</label>
      </div>
      <div>
          <input type="radio" id="treemap" value="treemap" v-model="visualisation" />
          <label class="ms-1" for="treemap">Treemap</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GraphOptions",
  computed: {
    visualisation: {
      get() {
        return this.$store.getters.getDisplay.active;
      },
      set(value) {
            this.$store.commit('SET_DISPLAYED_GRAPH', value)
      }      
    },
    xAxisBy: {
      get() {
        return this.$store.getters.getDisplay.graphs["org_benchmark"]["xAxisBy"];
      },
      set(value) {
        this.$store.commit("TOGGLE_GRAPH_DISPLAY_SETTING", {
          graph_title: "org_benchmark",
          property: "xAxisBy",
          value: value,
        });
        this.$store.dispatch("make_timeline_chart");
      },
    },
    yAxisBy: {
      get() {
        return this.$store.getters.getDisplay.graphs["org_benchmark"]["yAxisBy"];
      },
      set(value) {
        this.$store.commit("TOGGLE_GRAPH_DISPLAY_SETTING", {
          graph_title: "org_benchmark",
          property: "yAxisBy",
          value: value,
        });
        this.$store.dispatch("make_timeline_chart");
      },
    },
    stackBy: {
      get() {
        return this.$store.getters.getDisplay.graphs["org_benchmark"]["stackBy"];
      },
      set(value) {
          this.$store.commit("TOGGLE_GRAPH_DISPLAY_SETTING", {
          graph_title: "org_benchmark",
          property: "stackBy",
          value: value,
        });
        this.$store.dispatch("make_timeline_chart");
      },
    },
  },
};
</script>

<style>
.graph_options {
  z-index:50;
}
</style>